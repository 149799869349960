<template>
	<div class="card card-client">
		<div class="card-content">
			<p class="title is-4">#{{client.codeClient}} <small>{{client.prenom}} {{client.nom}}</small></p>
			<p class="subtitle is-6">
				<template v-if="client.wildcard">
					🥇 Accès wildcard
				</template>
				<template v-else>
					<a :href="'mailto:'+client.email">📧 {{client.email}}</a>
					
					&nbsp; / &nbsp;
					<a class="lien-mahalo" target="_blank" :href="lienMahalo(client)">
						<img width="12" src="https://mahalo-app.io/favicon.ico"> Ouvrir la fiche client dans Mahalo (Aboweb)
					</a>
				</template>
				&nbsp; / &nbsp;
				<a v-if="detail" @click="detail=false">👀 Masquer les données brutes</a>
				<a v-else @click="detail=true">👀 Données brutes</a>
			</p>

			<div class="content">
				<blockquote v-html="formatAdresse(client)"></blockquote>
				<small v-if="client.depuis"><time>Compte client créé le {{client.depuis}}</time></small>
			</div>
			<div>
				<section v-if="detail" class="section">
					<pre>{{client}}</pre>
				</section>
			</div>

			<p>
				<a :href="'https://dashboard.stripe.com/search?query='+client.email" target="_blank"><img width="16" height="16" src="https://dashboard.stripe.com/favicon.ico"> Voir dans Stripe</a>
				/
				<a :href="'https://www.paypal.com/activities/?fromDate='+lastYear+'&toDate='+today+'&searchType=ANY&searchKeyword='+client.email+'&transactiontype=ALL_TRANSACTIONS&archive=ACTIVE_TRANSACTIONS'" target="_blank"><img width="16" src="https://www.paypalobjects.com/favicon.ico"> Voir dans Paypal</a>
			</p>

		</div>
	</div>
	
</template>
<script>
	export default {
		data() {
			return {
				detail:false
			}

		},
		props : ['client']
	}
</script>
<style>
blockquote:empty {
	display: none;
}
.card-client {
	margin-bottom: 2em;
}
</style>